import { render, staticRenderFns } from "./ProjectDetailsForm.vue?vue&type=template&id=03eb7523&scoped=true&"
import script from "./ProjectDetailsForm.vue?vue&type=script&lang=js&"
export * from "./ProjectDetailsForm.vue?vue&type=script&lang=js&"
import style0 from "./ProjectDetailsForm.vue?vue&type=style&index=0&id=03eb7523&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03eb7523",
  null
  
)

export default component.exports