<template>
  <form
    class="ProjectDetailsForm"
    @submit.prevent="onSubmit">
    <!-- Case number -->
    <div class="FormSection">
      <span class="SectionTitle">{{ mixWB('CASE_NUMBER') }}</span>
      <InputField
        name="screeningNumber"
        type="number"
        :value="screeningNumber"
        :placeholder="mixWB('CASE_NUMBER_PLACE_HOLDER_TEXT')"
        @on-update="onScreeningNumberUpdate" />
      <div
        v-if="showScreeningNumberSuggestion"
        class="SuggestionsWrap">
        <span class="Title">{{ mixWB('SUGGESTIONS') }}:</span>
        <div class="List">
          <Chip
            :text="nextScreeningNumber.toString()"
            clickable
            @chip-click="onScreeningNumberUpdate({ value: nextScreeningNumber})" />
        </div>
      </div>
    </div>

    <!-- Screener -->
    <div class="FormSection">
      <span class="SectionTitle">{{ mixWB('SCREENER') }} ({{ mixWB('OPTIONAL') }})</span>
      <Dropdown
        name="screeners"
        :options="screeners"
        :value="selectedScreener"
        @on-update="onScreenerSelect" />
    </div>

    <!-- Type of project -->
    <div class="FormSection">
      <span class="SectionTitle">{{ mixWB('TYPE_OF_PROJECT') }}</span>
      <div class="RadioButtonWrap">
        <RadioButton
          :text="mixWB('RENOVATION')"
          groupName="project-type"
          value="renovation"
          :selectedValue="projectType"
          @radio-button-click="onRadioButtonClick" />
      </div>
      <div class="RadioButtonWrap">
        <RadioButton
          :text="mixWB('TOTAL_DEMOLITION')"
          groupName="project-type"
          value="total-demolition"
          :selectedValue="projectType"
          @radio-button-click="onRadioButtonClick" />
      </div>
    </div>

    <!-- Project description -->
    <div class="FormSection Last">
      <span class="SectionTitle">
        {{ mixWB('DESCRIPTION') }} ({{ mixWB('OPTIONAL') }})
      </span>
      <Textarea
        name="project-description"
        :value="projectDescription"
        :placeholder="mixWB('PROJECT_DESCRIPTION_PLACE_HOLDER_TEXT')"
        @on-update="onTextareaInput" />
    </div>

    <!-- Questions -->
    <div class="FormSection">
      <span class="SectionTitle">{{ mixWB('OTHER') }}</span>
      <Checkbox
        name="waste-review"
        :isSelected="doWasteReview"
        :text="mixWB('INCLUDE_WASTE_REVIEW')"
        @checkbox-click="doWasteReview = !doWasteReview" />
    </div>

    <!-- Errors -->
    <span
      class="ErrorText"
      v-if="errorText">{{ errorText }}</span>

    <!-- Button -->
    <Button
      type="submit"
      :text="buttonText"
      :isLoading="isLoading" />
  </form>
</template>

<script>
import { mapGetters } from 'vuex'
import Dropdown from '@/components/FormElements/Dropdown.vue'
import Textarea from '@/components/FormElements/Textarea.vue'
import RadioButton from '@/components/FormElements/RadioButton.vue'
import Button from '@/components/Buttons/Button.vue'
import Checkbox from '@/components/FormElements/Checkbox.vue'
import InputField from '@/components/FormElements/InputField.vue'
import Chip from '@/components/Chips/Chip.vue'

export default {
  name: 'ProjectDetailsForm',
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      screeningNumber: '',
      showScreeningNumberSuggestion: false,
      selectedScreener: '',
      projectType: '',
      doWasteReview: true,
      projectDescription: '',
      errorText: '',
    }
  },
  computed: {
    ...mapGetters([
      'currentScreeningData',
      'metaData',
      'nextScreeningNumber',
      'allUsers',
      'currentUser',
    ]),
    screeners() {
      return this.allUsers.reduce((prev, user) => {
        if (user.id === this.currentUser.id && this.currentUser.restrictions.isAdmin) {
          prev.push({
            value: user.id,
            name: `${ user.fullName } (admin)`,
          })
        }
        else if (user.restrictions.isScreener) {
          prev.push({
            value: user.id,
            name: user.fullName,
          })
        }

        return prev
      }, [
        { value: '', name: this.mixWB('SELECT_SCREENER') },
      ])
    },
  },
  methods: {
    onSubmit() {
      if (!this.screeningNumber || !this.projectType) {
        this.errorText = this.mixWB('PROJECT_DETAILS_FORM_ERROR_TEXT')
        return
      }
      this.$emit('form-submit', {
        screeningNumber: this.screeningNumber ? Number(this.screeningNumber) : null,
        selectedScreener: this.selectedScreener,
        projectType: this.projectType,
        doWasteReview: this.doWasteReview,
        projectDescription: this.projectDescription,
      })
    },
    onScreeningNumberUpdate({ value }) {
      this.errorText = ''
      this.screeningNumber = value.toString()
    },
    onScreenerSelect({ value }) {
      this.selectedScreener = value
    },
    onRadioButtonClick(value) {
      this.errorText = ''
      this.projectType = value
    },
    onTextareaInput({ value }) {
      this.errorText = ''
      this.projectDescription = value
    },
    setDataOnLoad() {
      this.screeningNumber = this.currentScreeningData.screeningNumber?.toString() || ''
      this.projectType = this.metaData.projectType || ''
      this.doWasteReview = this.metaData.doWasteReview ?? true
      this.projectDescription = this.metaData.projectDescription || ''

      // Set screener
      if (this.currentScreeningData.userID) {
        this.selectedScreener = this.currentScreeningData.userID
      }
      else if (this.currentUser.restrictions.isScreener) {
        this.selectedScreener = this.currentUser.id
      }

      if (!this.screeningNumber) {
        this.showScreeningNumberSuggestion = true
      }
    },
  },
  components: {
    RadioButton,
    Textarea,
    Button,
    Checkbox,
    InputField,
    Chip,
    Dropdown,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .FormSection
    padding-top 5px
    margin-bottom 20px
    &.Last
      margin-bottom 10px

  .SectionTitle
    display block
    margin-bottom 10px
    border-bottom 1px solid $color_grey_lighter
    font-size 0.875rem
    text-transform uppercase
    padding-bottom 3px

  .SuggestionsWrap
    display flex
    align-items center
    .Title
      margin-right 10px
    .List
      display flex

  .RadioButtonWrap
    margin-bottom 10px

  .ErrorText
    display block
    margin-bottom 10px
    color $color_error
    font-size 0.875rem

</style>
