<template>
  <div
    class="Textarea"
    :class="rootClasses">
    <div
      class="InnerWrap">
      <textarea
        ref="inputField"
        :class="{ isExpanded: isFocused || !!value || !!tempValue }"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :autocomplete="disableAutoSuggest ? 'off' : 'on'"
        v-html="value"
        @input='onInput'
        @focus="onFocus"
        @blur="onBlur" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: 'grey', // 'white' | 'grey'
    },
    focusTrigger: {
      type: Number,
      required: false,
      default: 0,
    },
    disableAutoSuggest: {
      type: Boolean,
      required: false,
      default: false,
    },
    autoExpand: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      isFocused: false,
      tempValue: '', // Used to keep expantion on newly created fields
    }
  },
  computed: {
    rootClasses() {
      return {
        BackgroundColorGrey: this.backgroundColor === 'grey',
        BackgroundColorWhite: this.backgroundColor === 'white',
        AutoExpand: this.autoExpand,
      }
    },
  },
  watch: {
    focusTrigger() {
      this.$refs.inputField.focus()
    },
  },
  methods: {
    onInput(e) {
      this.tempValue = e.target.value
      this.$emit('on-update', { name: this.name, value: e.target.value })
    },
    onFocus(e) {
      this.isFocused = true
      this.$emit('focus', { name: this.name, value: e.target.value })
    },
    onBlur(e) {
      this.isFocused = false
      this.$emit('blur', { name: this.name, value: e.target.value })
    },
  },
}
</script>

<style lang="stylus" scoped>
.Textarea
  margin-bottom 10px
  textarea
    min-height 100px
    border 1px solid $color_grey_lighter
    padding 10px
  &.BackgroundColorGrey
    textarea
      background-color $color_grey_lightest
  &.BackgroundColorWhite
    textarea
      background-color #fff
  &.AutoExpand
    textarea
      height 42px
      min-height 42px
      &.isExpanded
        height 100px
        min-height 100px

.InnerWrap
  position relative

</style>
