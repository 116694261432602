<template>
  <div
    class="Chip"
    :class="rootClasses"
    @click="onClick">
    <!-- Icon -->
    <div
      v-if="icon"
      class="Icon">
      <component :is="icon"/>
    </div>

    <!-- Text -->
    <span class="Text">{{ text }}</span>
  </div>
</template>

<script>
export default {
  name: 'Chip',
  props: {
    text: {
      type: String,
      required: true,
    },
    icon: {
      type: Object,
      required: false,
      default: () => null,
    },
    size: {
      type: String,
      required: false,
      default: 'medium', // 'small', 'medium'
    },
    clickable: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    rootClasses() {
      return {
        HasIcon: !!this.icon,
        SizeSmall: this.size === 'small',
        SizeMedium: this.size === 'medium',
        Clickable: this.clickable,
      }
    },
  },
  methods: {
    onClick() {
      if (!this.clickable) {
        return
      }
      this.$emit('chip-click')
    },
  },
}
</script>

<style lang="stylus" scoped>
  .Chip
    display flex
    align-items center
    background-color #fff
    border 1px solid $color_grey_lighter
    overflow hidden
    &.SizeSmall
      padding 2px 5px 2px
      border-radius 4px
      .Text
        font-size 0.75rem
        line-height 1
    &.SizeMedium
      height 30px
      padding 0 10px
      border-radius 5px
    &.HasIcon
      padding-left 0
    &.Clickable
      cursor pointer
      &:hover
        background-color $color_grey_lightest

  .Icon
    background-color $color_grey_lightest
    border-right 1px solid $color_grey_lighter
    box(30px, 30px)
    padding 6px
    margin-right 7px

  .Text
    display block
    line-height 1

</style>
